
body {
  margin:0;
  padding:0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f3f5f6;
}

/* Applies Inter to even the primereact components and all */
* {
    font-family: 'Inter', sans-serif !important;
  }

/* Ensure PrimeIcons are not affected by the font override */
.pi {
  font-family: "PrimeIcons" !important;
}
