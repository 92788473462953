span.p-accordion-toggle-icon.pi.pi-chevron-right{
    display: none;
}

 span.p-accordion-toggle-icon.pi.pi-chevron-down{
    display: none;
}

.p-image-preview-container > img{
    border-radius: 3px;
}

.cardsContainer{
    margin-bottom: 7rem; 
    display: flex;
    flex-direction: column;
    align-items: center;
}

 JobCardsAccordion{
    font-family: 'Inter', sans-serif;
}

/* The scoll down accordian has extra padding than required */
.p-accordion .p-accordion-content{
    padding: '0';
}

.p-accordion .p-accordion-header .p-accordion-header-link{
    display: block;
}

/* Remove the space between the applybutton and dialogbox in the bottom  */
#dialogJob > div.p-dialog-footer
{
    padding: 0;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}

#dialogFooterButton{
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}


/* Mobile Responsive */
@media screen and (max-width: 600px) {

    .p-tabview .p-tabview-panels{
        padding : 0 !important
      }

    .p-accordion .p-accordion-content{
      padding: 0 !important
    }    

     div.m-1{
     padding: 0 !important;
    }
     
    .applyBtn{
        width: 45rem;
    }

    .applyButton{
        position: fixed;
        bottom: 0;
        width: 100vw;
        height: 3rem;
        font-weight: bold;
    }

    .dialogTags{
        display: none;
    }

    .JobDialog{
        width: 100vw !important;
        height: 100vh !important;
        margin-bottom: 0 !important;
    }
   }

   @media screen and (max-width: 950px){
    .postedDate{
        display: none;  
    }
   }
   
