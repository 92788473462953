.postAJob {
    outline: 0;
    position: relative;
    display: inline-block;
    padding: 11px 22px;
    font-size: 17px;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid #6366F1;
    background: #6366F1;
    color: rgb(1 25 45);
    font-weight: 600;
    transition: box-shadow 0.5s ease-in-out, border-color 0.5s ease-in-out;
}

.postAJob:hover {
    background-color: #6366F1;
    color: #000;
    box-shadow: 0 0 5px #6366F1,
        0 0 20px rgba(128, 105, 180, 0.8),
        0 0 35px rgba(128, 105, 180, 0.8),
        0 0 50px rgb(128, 105, 180, 0.8);
    border-color: #6366F1;
}


.arrow {
    transform: translate(-50%, -50%);
    transform: rotate(360deg);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.arrow span {
    display: block;
    width: 1vw;
    height: 1vw;
    /* color: #0f172a; */
    /* color: blueviolet; */
    border-right: 4px solid #6366F1; 
    border-bottom: 4px solid #6366F1;
    transform: rotate(45deg);
    animation: animate 2s infinite;
    /* background-color: 1px solid blueviolet; */
}

.arrow span:nth-child(2) {
    animation-delay: -0.2s;
}

.arrow span:nth-child(3) {
    animation-delay: -0.4s;
}

.Menubar{
    z-index: 9999;
    position: absolute;
    top: 10;
}

#heroTitle {
    background-clip: text;
    background-image: linear-gradient(180deg, rgb(114, 9, 212), rgb(40, 50, 212) 33%, rgb(5, 203, 217));
    color: transparent;
    font-weight: 700;
}

#heroSubTitle{
    background-clip: text;
    /* background-image: linear-gradient(90deg, #7909e1, #1a24d6 33%, #01b3c0); */
    color: #000000d0;
    font-weight: 600;
}

.HeroImage > img{
    position: absolute;
    top: 0;
    z-index: -1;
    width: 100%;
    height: auto;
    filter: blur(7px);
    -webkit-filter: blur(7px);
}

@media screen and (max-width: 900px) {
    .HeroImage {
        display: none;
    }

    .HeroImageMobile>img {
        position: absolute;
        top: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
    }
}

@media screen and (min-width: 900px) {
    .HeroImageMobile {
        display: none;
    }

    .HeroImage > img {
        position: absolute;
        top: 0;
        z-index: -1;
        width: 100%;
        height: auto;
        filter: blur(7px);
        -webkit-filter: blur(7px);
    }
}


@keyframes animate {
    0% {
        opacity: 0;
        transform: rotate(45deg) translate(-20px, -20px);
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: rotate(45deg) translate(15px, 15px);
    }
}