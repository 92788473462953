
/* DockDemo.css */

.dock-demo{
    position: fixed;
} 

.dock-demo .dock-window {
    width: 100%;
    height: 450px;
    /* position: relative; */
    background-repeat: no-repeat;
    background-size: cover;
}

.dock-demo .p-dock {
    position: fixed;
    /* z-index: 9999999; */
}